import {
  HighlightsCard,
  HighlightsCardSkeleton,
} from "../../highlights-page/components";
import { ICategory } from "../../../utils/hooks/useGetHighlightsTabs";
import { Carousel, HighlightsErrorCard } from "../../../components";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import Swiper from "swiper";
import { atom, getDefaultStore } from "jotai";
import { useGetHighlights } from "../../../utils/hooks/useGetHighlights";
import { mq } from "../../../config/styles";

interface IHighlightsCarousel {
  category: ICategory | undefined;
}

const slidesAtoms = {
  amusing: atom(0),
  stinking: atom(0),
  basic: atom(0),
  all: atom(0),
};

/**
 * Renders the highlights section in the home page
 * Renders a nested carousel using Swiper lib
 */
export const HighlightsCarousel = (props: IHighlightsCarousel) => {
  const { cardsHighlightsData, isError, isLoading } = useGetHighlights();
  const { getSingleHighlightsPath, getTeamViewPath } = useGetUrl();
  const defaultStore = getDefaultStore();

  // store swiper instance
  const [swiper, setSwiper] = useState<Swiper | null>(null);

  useEffect(() => {
    if (!props.category || !swiper) return;

    swiper.slideTo(defaultStore.get(slidesAtoms[props.category.category_id]));
  }, [props.category?.category_id]);

  if (isError || cardsHighlightsData === null) {
    return <HighlightsErrorCard />;
  }

  if (isLoading) {
    return <HighlightsCardSkeleton />;
  }

  return (
    <>
      {cardsHighlightsData && props.category && (
        <Carousel
          onSwiper={(s) => {
            setSwiper(s);
          }}
          accentColor={props.category?.accent_color}
          showBullets
          carouselAtom={slidesAtoms[props.category.category_id]}
        >
          {cardsHighlightsData
            .filter((info) => info.category_id === props.category!.category_id)
            .sort((a, b) => a.highlight_order - b.highlight_order)
            .map((info) => (
              <HighlightsCard
                key={`${info.category_id}${info.highlight_id}`}
                toHighlightURL={getSingleHighlightsPath(
                  info.category_id,
                  info.highlight_id
                )}
                cardStyles={carouselCardsStyle}
                accentColor={props.category?.accent_color!}
                chipImageURL={props.category?.category_icon}
                highlightCardData={info}
                toTeamViewURL={getTeamViewPath}
              />
            ))}
        </Carousel>
      )}
    </>
  );
};

const carouselCardsStyle = css`
  ${mq["md"]} {
    height: 16rem !important;
  }
`;
