import { mq } from "../../../config/styles";
import { css } from "@emotion/react";
import { Accordion, Button, Input } from "../../../components";
import { TEAM_DETAIL_PAGE } from "../../../constants/auth-pages.constants";
import { Link } from "react-router-dom";
import teamIdGuideImage from "../../../assets/auth-pages/team-id-guide.png";
import { Form, Formik } from "formik";
import userIcon from "../../../assets/icons/user.svg";
import { root } from "../styles";
import {
  TeamDetailsType,
  TeamDetailsYupSchema,
} from "../../../utils/validation";

interface IInputTeamId {
  onSubmit: (
    values: TeamDetailsType,
    { setErrors, setSubmitting }: { setErrors: any; setSubmitting: any }
  ) => void;
  onBackClick: () => void;
}
export const InputTeamId = (props: IInputTeamId) => {
  const initialValues = {
    team_id: "",
    generalErr: "",
  };

  return (
    <div>
      <Formik
        initialValues={initialValues as any}
        validationSchema={TeamDetailsYupSchema}
        onSubmit={props.onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form css={root(isSubmitting)}>
            <Input
              Icon={<img src={userIcon} />}
              placeholder={TEAM_DETAIL_PAGE.TEAM_ID_FIELD_PLACEHOLDER}
              id="team_id"
              name="team_id"
            />
            <Button
              button="CTA"
              type="submit"
              disabled={isSubmitting || values.team_id.length === 0}
            >
              {TEAM_DETAIL_PAGE.CTA_BUTTON_TEXT}
            </Button>
          </Form>
        )}
      </Formik>

      <Accordion title={TEAM_DETAIL_PAGE.FIND_TEAM_ID.TITLE}>
        <div css={steps}>
          <ol>
            <li>
              {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.ONE.TEXT}{" "}
              <Link
                css={linkStyle}
                to={TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.ONE.LINK}
              >
                {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.ONE.LINK_TEXT}
              </Link>
            </li>

            <li>{TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.TWO}</li>
            <li>{TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.THREE}</li>

            <li>
              {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.FOUR.FIRST_PART}{" "}
              <b>{TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.FOUR.ONLY}</b>{" "}
              {TEAM_DETAIL_PAGE.FIND_TEAM_ID.STEPS.FOUR.LAST_PART}
            </li>
          </ol>

          <img src={teamIdGuideImage} alt="Team Id Guide" />
        </div>
      </Accordion>
    </div>
  );
};

const steps = css`
  h1 {
    font-size: 1.25rem;
  }

  ol {
    margin: 0;
    padding: 0 1rem;
    font-size: 0.875rem;
    li {
      list-style: decimal;
      padding: 0 0 0.1rem 0.5rem;
    }
  }

  img {
    margin-top: 1rem;
    width: 100%;
  }

  ${mq["md"]} {
    h1 {
      font-size: 1.75rem;
    }
    ol {
      font-size: 1.125rem;
    }
  }
`;

const linkStyle = css`
  color: white;
  text-decoration: underline;
`;
