export const TermsOfServiceIcon = ({
  fill = "#8E8E8E",
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29289 1.44719C9.48043 1.25966 9.73478 1.1543 10 1.1543H18C19.6569 1.1543 21 2.49745 21 4.1543V20.1543C21 21.8112 19.6569 23.1543 18 23.1543H6C4.34315 23.1543 3 21.8112 3 20.1543V8.1543C3 7.88908 3.10536 7.63473 3.29289 7.44719L9.29289 1.44719ZM18 3.1543H11V8.1543C11 8.70658 10.5523 9.1543 10 9.1543H5V20.1543C5 20.7066 5.44772 21.1543 6 21.1543H18C18.5523 21.1543 19 20.7066 19 20.1543V4.1543C19 3.60202 18.5523 3.1543 18 3.1543ZM6.41421 7.1543H9V4.56851L6.41421 7.1543ZM7 13.1543C7 12.602 7.44772 12.1543 8 12.1543H16C16.5523 12.1543 17 12.602 17 13.1543C17 13.7066 16.5523 14.1543 16 14.1543H8C7.44772 14.1543 7 13.7066 7 13.1543ZM7 17.1543C7 16.602 7.44772 16.1543 8 16.1543H16C16.5523 16.1543 17 16.602 17 17.1543C17 17.7066 16.5523 18.1543 16 18.1543H8C7.44772 18.1543 7 17.7066 7 17.1543Z"
        fill={fill}
      />
    </svg>
  );
};
