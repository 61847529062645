import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useQuery } from "react-query";
import { IFplManager } from "../types/types";
import { useUserContext } from "../../context/UserContext";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";

export interface IMiniLeagueCaptainsTableRes {
  team: {
    name: string;
    short_name: string;
    team_id: number;
  };
  name: {
    web_name: string;
    first_name: string;
    last_name: string;
  };
  position: string;
  count: number;
  owned_percentage: string;
  owned_ratio: string;
  shirtLink: string;
  gwHistoryPoints: { [key: string]: number | undefined };
  owned_by: IFplManager[];
}

/**
 * fetch mini league captains data if history is true fetch standing table history view
 * if history is false fetch captains table and captains cards
 */

export const useGetMiniLeagueCaptainTable = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useUserContext();
  const { selectedLeague } = useGlobalInfoContext();

  const queryKey = [
    {
      user,
      leagueId: params[PATH_PARAMS.LEAGUE_ID]!,
      gw: searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
      selectedLeague,
    },
  ];

  const queryFunction = async () => {
    if (
      user &&
      user.teamId &&
      params[PATH_PARAMS.LEAGUE_ID] &&
      searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK) &&
      selectedLeague
    ) {
      if (selectedLeague.rank_count! > 500) {
        return null;
      }

      const { data } = await axiosInstance.get<IMiniLeagueCaptainsTableRes[]>(
        FPL_CHAMP_ENDPOINTS.MINI_LEAGUE_CAPTAINS(
          params[PATH_PARAMS.LEAGUE_ID]!,
          searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
          user.teamId
        )
      );

      return data;
    }
  };

  const {
    isLoading,
    data: miniLeagueCaptains,
    isFetching,
  } = useQuery({
    queryKey: queryKey,
    queryFn: queryFunction,
  });

  return {
    miniLeagueCaptainsCardData: miniLeagueCaptains?.slice(0, 3),
    miniLeagueCaptainsTableData: miniLeagueCaptains,
    isLoading,
    isFetching,
  } as const;
};
