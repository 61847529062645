import { Pagination } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import {
  TransferRow,
  TransfersCardSkeleton,
} from "../../transfers-pages/components";
import {
  IGetLeagueTransfersRes,
  useGetLeagueTransfers,
} from "../../../utils/hooks/useGetLeagueTransfers";
import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { homeCardBgColor, cardBorderRadius } from "../../../config/styles";
import { TRANSFERS_PAGE_CONTENT } from "../../../constants/transfers.constants";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { Button } from "../../../components";
import { Link, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../../config/routes";
import React from "react";
import { secondaryColor } from "../../../config/theme";
import { FPL_CHIPS } from "../../../constants/view-team-pages.constants";
import { getPointsColor } from "../../../utils/general.helpers";
import { TRANSFERS_WIDGET } from "../../../constants/home-page.constants";

export const TransfersWidget = () => {
  const { leagueTransfers } = useGetLeagueTransfers(1, true);

  return (
    <div>
      <Swiper
        modules={[Pagination]}
        spaceBetween={16}
        pagination={{
          clickable: true,
          el: ".bullet-container",
        }}
      >
        {!leagueTransfers ? (
          <TransfersCardSkeleton length={2} showBtn={false} />
        ) : (
          leagueTransfers.leagueTransfers.slice(0, 3).map((transfer) => (
            <SwiperSlide key={transfer.entry}>
              <TransfersWidgetCard transfers={transfer} />
            </SwiperSlide>
          ))
        )}

        <Stack className="bullet-container" css={bulletsStyles} />
      </Swiper>
    </div>
  );
};

const TransfersWidgetCard = ({
  transfers,
}: {
  transfers: IGetLeagueTransfersRes["leagueTransfers"][0];
}) => {
  const { getManagerTransfersPath } = useGetUrl();
  const [searchParams] = useSearchParams();
  const transferInfo = transfers.transfers[
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!
  ] as
    | IGetLeagueTransfersRes["leagueTransfers"][0]["transfers"][" "]
    | undefined;

  const { getTeamViewPath } = useGetUrl();

  const activeChip = transferInfo?.active_chip
    ? FPL_CHIPS[transferInfo?.active_chip]
    : null;
  const GW = searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK);
  const remainingTransfersCount =
    Object.keys(transfers.transfers).length - 2 > 0;
  const TitleComp = transfers?.entry ? Link : React.Fragment;
  return (
    <Stack css={transfersSummaryStyles}>
      <Stack css={topSectionContainerStyles}>
        <Stack>
          <Stack css={transfersSummaryTitleStyles}>
            <TitleComp
              css={titleSection}
              to={getTeamViewPath(transfers?.entry!)}
            >
              <Typography variant="h1">
                {transfers.fpl_team_name}{" "}
                {activeChip && <img src={activeChip.img} alt={"chip"} />}
              </Typography>
              <Typography>GW {GW} transfers:</Typography>
            </TitleComp>

            {/** Points can be zero which is a falsy value */}
            {transferInfo &&
              transferInfo.event_transfers_cost !== undefined && (
                <Typography css={netPointsStyles}>
                  Net Points{" "}
                  {`${transferInfo.net_points} (- ${transferInfo.event_transfers_cost})`}{" "}
                  =
                  <span
                    style={{
                      color: getPointsColor(
                        transferInfo.net_points -
                          transferInfo.event_transfers_cost
                      ),
                    }}
                  >
                    {" "}
                    {transferInfo.net_points -
                      transferInfo.event_transfers_cost}{" "}
                    Pts
                  </span>
                </Typography>
              )}
          </Stack>
        </Stack>
      </Stack>

      {transferInfo && transferInfo.event_transfers.length !== 0 ? (
        <Stack css={transfersContainerStyles}>
          {transferInfo.event_transfers.map((item, i) => (
            <TransferRow
              key={i}
              element_in_info={item.element_in_info}
              element_out_info={item.element_out_info}
            />
          ))}
        </Stack>
      ) : (
        <p css={noTransfersStyles}>
          {TRANSFERS_PAGE_CONTENT.TRANSFERS_CARD.NO_TRANSFERS}
        </p>
      )}

      {remainingTransfersCount && (
        <Button
          button="gradient"
          css={btnStyles}
          to={getManagerTransfersPath(transfers.entry)}
        >
          {TRANSFERS_WIDGET.BTN(Object.keys(transfers.transfers).length - 2)}
        </Button>
      )}
    </Stack>
  );
};

const transfersSummaryTitleStyles = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    max-width: 50%;
    & > h1 {
      max-width: 100% !important;
    }
  }
`;

const titleSection = css`
  h1 {
    font-size: clamp(1.125rem, 4vw, 1.675rem);
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
      width: 1.3rem;
    }
  }

  p {
    font-size: clamp(0.875rem, 1.5vw, 1rem);
  }
`;

const netPointsStyles = css`
  background: #222232;
  padding: 0.5rem 1rem;
  border-radius: 999rem;

  span {
    color: ${secondaryColor};
  }

  font-size: clamp(0.875rem, 1.5vw, 1rem);
  white-space: nowrap;
`;

const topSectionContainerStyles = css`
  padding: 0.5rem 1rem;
`;

const transfersSummaryStyles = css`
  ${homeCardBgColor}
  ${cardBorderRadius}
  overflow: hidden;
  position: relative;

  & > *:not(:last-child) {
    border-bottom: 1px solid #78787831;
  }
`;

const transfersContainerStyles = css`
  max-height: 300px;
  overflow: auto;

  & > * {
    border-bottom: 1px solid #78787831;
  }
`;

const noTransfersStyles = css`
  font-weight: 700;
  text-align: center;
  font-size: clamp(1rem, 2vw, 1.5rem);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
`;

const btnStyles = css`
  padding: 0;
  height: fit-content;
  width: 100%;
  border-radius: 0;

  /* position: absolute;
  bottom: 0;
  left: 0; */
`;

const bulletsStyles = css`
  flex-direction: row;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  gap: clamp(0.4rem, 0.8vw, 0.7rem);

  .swiper-pagination-bullet {
    cursor: pointer;
    width: auto;
    height: clamp(0.6rem, 1.5vw, 0.75rem);
    aspect-ratio: 1/1;
    background: #8e8e8e;

    &.swiper-pagination-bullet-active {
      background: ${secondaryColor} !important;
    }
  }
`;
