export const MY_TEAM_SUMMARY = {
  TITLE: "My Team | GW",
  POINTS: "Points",
  CAPTAIN: {
    TITLE: "Captain:",
    POINTS: "Captain Points:",
  },
  BTN_TITLE: "View Team",
};

export const MINI_LEAGUE_SUMMARY = {
  TITLE: "Mini League | GW",
  LINK_TITLE: "View all Table >",
  ERROR_CARD: {
    TITLE: "Mini League | GW",
    ERR_MESSAGE: "The league standings are only available for the current GW",
  },
};

export const HOME_PAGE = {
  META: {
    TITLE: "Home",
  },
  FIXTURES_SUMMARY: {
    TITLE: "Fixtures | GW",
    LINK_TEXT: "View all Fixtures >",
    FULL_TIME: "FT",
    TODAY: "Today",
  },
};

export const TRANSFERS_WIDGET = {
  BTN: (tNumber: number) =>
    `Show ${tNumber} more transfer${tNumber > 1 ? "s" : ""}`,
};
