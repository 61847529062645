import { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  addTeamIdForConvertedOAuthUserByOAuth,
  refreshSessionSupabase,
  signOutSupabase,
} from "../pages/auth-pages/utils/authHelpers";
import { ERROR_MESSAGES } from "../config/contextApi.constants";
import { LoadingScreen } from "../components";
import { FPL_CHAMP_ENDPOINTS } from "../config/fplChampEndpoints.constants";
import { useGlobalInfoContext } from "./GlobalInfoContext";
import { axiosInstance } from "../config/axios.config";
import { SESSION_STORAGE } from "../config/app.config";
import { PATH_PARAMS, ROUTES } from "../config/routes";
import { getLeagueById } from "../utils/general.helpers";
import { IClassicLeagues } from "../utils/types/types";
import { useIsSharedURL } from "../utils/hooks/useIsSharedURL";

interface IUserProvider {
  user: IUser | null | undefined;
  setUser: React.Dispatch<React.SetStateAction<IUser | null | undefined>>;
  signOutUser: () => void;
  isLoadingScreenOpen: boolean;
}

export interface IUser {
  email: string;
  email_verified: boolean;
  id: string;
  teamId: number;
  selectedLeagueId: string | undefined;
  favoriteLeagueId: string | undefined;
  isAnonymous: boolean;
}

const userContext = createContext<IUserProvider | undefined>(undefined);

/**
 * Custom hook to access the user context.
 *
 * @returns {IUserProvider} The context value containing the user state, setter, and sign-out function.
 * @throws {Error} Throws an error if the context is not provided.
 */
export const useUserContext = () => {
  const userState = useContext(userContext);

  if (!userState) throw new Error(ERROR_MESSAGES.NO_USER_CONTEXT_PROVIDER);

  return userState;
};

/**
 * Context provider for managing user state and authentication.
 *
 * @description
 * Provides context for managing the user state and authentication, including:
 * - `user`: The current user or `null` if not authenticated.
 * - `setUser`: Function to update the user state.
 * - `signOutUser`: Function to sign out the user.
 *
 * Handles user session refresh, updates user state based on session data, and manages
 * user-related loading screens and league information.
 *
 * Display a loading screen while user auth state is being retrieved
 */

export const UserContextProvider = () => {
  const { isShared } = useIsSharedURL();
  const [user, setUser] = useState<IUser | null | undefined>();
  const [isLoadingScreenOpen, setIsLoadingScreenOpen] = useState(true);
  const { selectedLeague, setSelectedLeague, leagues, setLeagues } =
    useGlobalInfoContext();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      let { user, error } = await refreshSessionSupabase();
      if (error || !user) return setUser(null);

      user = await addTeamIdForConvertedOAuthUserByOAuth(user);

      if (
        user &&
        sessionStorage.getItem(SESSION_STORAGE.TEMP_TEAM_ID) &&
        sessionStorage.getItem(SESSION_STORAGE.TEMP_SELECTED_LEAGUE_ID)
      ) {
        return setUser({
          ...user,
          teamId: Number(sessionStorage.getItem(SESSION_STORAGE.TEMP_TEAM_ID)),
          selectedLeagueId: sessionStorage.getItem(
            SESSION_STORAGE.TEMP_SELECTED_LEAGUE_ID
          )!,
        });
      }

      if (user && sessionStorage.getItem(SESSION_STORAGE.TEMP_TEAM_ID)) {
        return setUser({
          ...user,
          teamId: Number(sessionStorage.getItem(SESSION_STORAGE.TEMP_TEAM_ID)),
        });
      }

      // Rollbar user
      /** save user data in session to access in error boundary component */
      if (user) {
        sessionStorage.setItem(
          SESSION_STORAGE.ROLLBAR_USER,
          JSON.stringify({
            email: user.email,
            teamId: user.teamId,
            isAnon: user.isAnonymous,
          })
        );
      }

      setUser(user);
    })();
  }, []);

  useEffect(() => {
    const closeLoadingScreen =
      (user && !user.teamId) || // user registered and still doesn't have teamId
      (user && user.teamId && selectedLeague) || // user registered and has teamId
      user === null; // user is signed out or doesn't have account

    if (closeLoadingScreen) {
      setIsLoadingScreenOpen(false);
    }
  }, [user, selectedLeague]);

  useEffect(() => {
    if (user && user.teamId) {
      (async function () {
        const { data } = await axiosInstance.get<IClassicLeagues[]>(
          FPL_CHAMP_ENDPOINTS.GET_LEAGUES(String(user.teamId))
        );

        setLeagues(data);
      })();
    }
    endAnonUserSession();
  }, [user]);

  /** Set the league that the user id not a port of or for a shared view */
  const setNonUserLeague = async () => {
    if (
      !params[PATH_PARAMS.LEAGUE_ID] ||
      params[PATH_PARAMS.LEAGUE_ID] === "undefined"
    ) {
      return;
    }

    const { data } = await axiosInstance.get<IClassicLeagues>(
      FPL_CHAMP_ENDPOINTS.GET_LEAGUE_INFO(
        params[PATH_PARAMS.LEAGUE_ID]!,
        isShared
      )
    );
    setSelectedLeague(data);
  };

  useEffect(() => {
    if (isShared) {
      setNonUserLeague();
    }
    if (!user) return;
    if (leagues.length === 0) return;

    if (params[PATH_PARAMS.LEAGUE_ID]) {
      const league = getLeagueById(leagues, params[PATH_PARAMS.LEAGUE_ID]!);
      if (league) {
        setSelectedLeague(league);
      } else {
        setNonUserLeague();
      }
      return;
    }

    // No league Id in URL
    const leagueId = [
      user.favoriteLeagueId,
      user.selectedLeagueId,
      leagues[0].id,
    ];

    for (let i = 0; i < leagueId.length; i++) {
      const league = getLeagueById(leagues, leagueId[i]!);
      if (league) {
        setSelectedLeague(league);
        break;
      }
    }
  }, [leagues, user, params]);

  const signOutUser = async () => {
    sessionStorage.removeItem(SESSION_STORAGE.ANON_USER_TEAM_ID);
    await signOutSupabase();
    setUser(null);
    setLeagues([]);
    setIsLoadingScreenOpen(false);
    if (user && !user.isAnonymous) {
      navigate(ROUTES.LANDING_PAGE);
    }
  };

  /**
   * If the user is an anonymous user, sign them out after 10 seconds of inactivity.
   * Inactivity is defined as the window losing focus or the page being closed.
   * The timer is reset whenever the window regains focus.
   */
  const endAnonUserSession = () => {
    const signOut = () => {
      console.log("signing out");
      if (user && user.isAnonymous) {
        signOutUser();
      }
    };

    let interval_id: any;
    window.onblur = () => {
      interval_id = setTimeout(signOut, 1000 * 60 * 60);
    };

    window.onfocus = () => {
      clearInterval(interval_id);
    };
  };

  if (isLoadingScreenOpen) return <LoadingScreen />;

  return (
    <userContext.Provider
      value={{
        user,
        setUser,
        signOutUser,
        isLoadingScreenOpen,
      }}
    >
      <Outlet />
    </userContext.Provider>
  );
};
