import { css } from "@emotion/react";
import { COOKIES_POLICY_PAGE_CONTENT } from "../../constants/privacyAndTerms.constants";
import { Helmet } from "react-helmet";

export const CookiePolicyPage = () => {
  return (
    <div css={container}>
      <Helmet>
        <title>{COOKIES_POLICY_PAGE_CONTENT.META.TITLE}</title>
      </Helmet>
      <h1>{COOKIES_POLICY_PAGE_CONTENT.TITLE}</h1>
      {COOKIES_POLICY_PAGE_CONTENT.CONTENT.map((section, index) => (
        <div key={index} css={sectionStyles}>
          <h2>{section.title}</h2>
          {section.beforeParagraphs.length > 0 &&
            section.beforeParagraphs.map((paragraph, pIndex) => (
              <p key={pIndex}>{paragraph}</p>
            ))}

          {section.bulletPoints.length > 0 && (
            <ul>
              {section.bulletPoints.map((point, bIndex) => (
                <li key={bIndex}>
                  <span>{point[0]}</span> {point[1]}
                </li>
              ))}
            </ul>
          )}

          {section.afterParagraphs.length > 0 &&
            section.afterParagraphs.map((paragraph, pIndex) => (
              <p key={pIndex}>{paragraph}</p>
            ))}
        </div>
      ))}
    </div>
  );
};

const container = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1 {
    color: #80cb4f;
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5rem; /* 100% */
  }
`;

const sectionStyles = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h2 {
    color: #fff;
    font-family: "Poppins";
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }

  p,
  li {
    margin: 0;
    color: #dddedf;
    font-size: 1rem;
    font-weight: 400;
  }

  span {
    font-weight: 700;
  }
`;
