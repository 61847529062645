import { Stack } from "@mui/material";
import {
  circularStyleMuiDataGridPlayerSummary,
  stackCenteredStyleMuiDataGrid,
} from "../../config/styles";
import { secondaryColor } from "../../config/theme";

interface IFixtureDifficultyCellProps {
  difficulty: number;
}
/** JSX for displaying fixture difficulty in EPL Player Summary table. */
export const FixtureDifficultyCell: React.FC<IFixtureDifficultyCellProps> = ({
  difficulty,
}) => {
  let color = secondaryColor;
  let textColor = "fff";

  if (difficulty === 2) {
    color = "#fff";
    textColor = "#000 !important";
  } else if (difficulty === 4) {
    color = "#f00";
  } else if (difficulty === 5) {
    color = "darkred";
  }

  return (
    <Stack css={stackCenteredStyleMuiDataGrid}>
      <Stack
        sx={{ backgroundColor: color, color: textColor }}
        css={circularStyleMuiDataGridPlayerSummary}
      >
        {difficulty}
      </Stack>
    </Stack>
  );
};
