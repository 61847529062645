import { css } from "@emotion/react";
import logo from "../../../assets/identity/logo_name.svg";
import { navbarHeight, tableHeaderBgColor } from "../../../config/theme";
import { container, mq } from "../../../config/styles";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";
import { Button } from "../../../components";
import { ROUTES } from "../../../config/routes";
import { Box, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { LANDING_PAGE_SECTION_IDS } from "../../../constants/landing-page.constants";
import { CTAButtonWrapper } from "./CTAButton";

const links = [
  {
    text: "Highlights",
    link: `#${LANDING_PAGE_SECTION_IDS.HIGHLIGHTS_SECTION}`,
  },
  {
    text: "Why FplChamp",
    link: `#${LANDING_PAGE_SECTION_IDS.WHY_FPLCHAMP_SECTION}`,
  },
  {
    text: "Demo",
    link: `#${LANDING_PAGE_SECTION_IDS.TRY_HIGHLIGHTS_SECTION}`,
  },
  {
    text: "Contact Us",
    link: ROUTES.CONTACT_US_PAGE,
  },
];

const CTAButton = CTAButtonWrapper(Button);

/** Renders the top navigation bar in the app  */
export const LandingPageNavbar = () => {
  const navigate = useNavigateState();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleDropDownMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <nav css={appBarStyle}>
        {/* Only displayed on small screens */}
        <Box css={dropDownMenuContainerStyles}>
          <IconButton
            css={dropDownMenuButtonStyles}
            onClick={handleDropDownMenuClick}
          >
            <MenuRoundedIcon />
          </IconButton>
          <Menu
            css={dropDownMenuStyles}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {links.map(({ text, link }) => (
              <MenuItem key={text} onClick={handleClose}>
                <a href={link}>{text}</a>
              </MenuItem>
            ))}

            <MenuItem>
              <Button button="text" to={ROUTES.SIGN_IN_PAGE}>
                Login
              </Button>
            </MenuItem>
          </Menu>
        </Box>

        <img
          css={logoStyle}
          src={logo}
          alt="Logo Image"
          onClick={() => navigate(ROUTES.LANDING_PAGE)}
        />

        {/* Only displayed on large screens */}
        <Stack component={"ul"} css={quickLinksStylesLg}>
          {links.map(({ text, link }) => (
            <li key={text}>
              <a href={link}>{text}</a>
            </li>
          ))}
        </Stack>

        <Box css={loginBTNsContainerStyles}>
          <CTAButton button="gradient" css={getStartedButton}>
            {"Get Started"}
          </CTAButton>
          <Button
            button="text"
            css={loginButtonStyles}
            to={ROUTES.SIGN_IN_PAGE}
          >
            Login
          </Button>
        </Box>
      </nav>
    </>
  );
};

// Define Emotion styles
const appBarStyle = css`
  position: relative;
  isolation: isolate;
  margin: 1rem;
  margin-bottom: 0;
  border-radius: 1rem;

  ${container}
  padding-block: 1rem !important;
  padding-inline: 0.5rem !important;
  height: ${navbarHeight};
  background-color: #2c2d3f;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    gap: 1rem;
  }

  ${mq["sm"]} {
    padding-inline: 0.5rem !important;
  }

  ${mq["lg"]} {
    justify-content: start;
  }
`;

const logoStyle = css`
  width: 11rem;
  object-fit: cover;
  cursor: pointer;

  ${mq["lg"]} {
    width: 13.0625rem;
  }
`;

const loginBTNsContainerStyles = css`
  display: flex;
  flex-direction: row;
  ${mq["md"]} {
    margin-left: auto;
  }
`;

const getStartedButton = css`
  width: fit-content;
  padding: 0 0.35rem;
  font-size: 0.8rem;

  ${mq["md"]} {
    font-size: 0.875rem;
    padding: 0.5rem 1.5rem;
  }
`;

const loginButtonStyles = css`
  display: none;
  color: #ffffff39;

  ${mq["md"]} {
    display: flex;
    max-width: fit-content;
    padding-inline: 0.2rem !important;
    height: 2.8rem;
  }
`;

const quickLinksStylesLg = css`
  display: none;

  ${mq["lg"]} {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: row;
    gap: 1rem;

    li a {
      text-transform: uppercase;
      font-weight: 700;
    }
  }
`;

const dropDownMenuStyles = css`
  top: 1rem;

  .MuiPaper-root {
    background-color: transparent;
    width: 100%;
  }

  .MuiMenu-list {
    border-radius: 1rem;
    width: 100%;
    overflow: hidden;
    background-color: ${tableHeaderBgColor};
    color: white;
    padding: 0;

    & > * {
      padding: 1rem 2rem;
      text-transform: capitalize !important;
      font-weight: 700;
      :not(:last-of-type) {
        border-bottom: 1px solid grey;
      }
    }
  }
`;

const dropDownMenuContainerStyles = css`
  ${mq["lg"]} {
    display: none !important;
  }
`;

const dropDownMenuButtonStyles = css`
  svg {
    color: white;
    font-size: 2rem;
  }
`;
