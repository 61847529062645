import { css } from "@emotion/react";
import { bgColor } from "../config/theme";
import { Field } from "formik";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import envelop from "../assets/contact-us/envelop.svg";
import { ErrorMessage } from "./ErrorMessage";

interface ITextField extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  limit: number;
}

/**
 * This component renders a customizable text area
 * It utilizes the `Field` component from `formik` for form integration and the `ErrorMessage` component
 */
export const TextField = ({ onChange, ...props }: ITextField) => {
  const [textNumber, setTextNumber] = useState(0);
  const [colors, setColors] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(e as any);
    const valueLength = e.target.value.length;

    // Update the textNumber state
    setTextNumber(valueLength);

    // Use valueLength directly instead of textNumber
    if (valueLength > props.limit) {
      setColors("red");
    } else if (valueLength <= props.limit && colors !== "") {
      setColors("");
    }
  };

  return (
    <div css={root}>
      <Stack css={inputContainer}>
        <Field
          component="textarea"
          css={inputStyle}
          onChange={handleChange}
          {...props}
        />
        <Typography
          color={colors}
          css={textNumberStyles}
        >{`${textNumber}/${props.limit}`}</Typography>
        <img src={envelop} css={envelopImageStyles} />
      </Stack>
      <ErrorMessage name={props.name} />
    </div>
  );
};

const root = css``;

const inputContainer = css`
  position: relative;
  background-color: ${bgColor};
  border-radius: 1.2rem;
  padding: 0rem 1.5rem; /** block padding defined on input */
  overflow: hidden;
  width: 100%;
  height: 15rem;
  display: flex;
  gap: 1rem;
  isolation: isolate;
`;

const textNumberStyles = css`
  font-size: clamp(0.75rem, 1vw, 1rem);
  opacity: 0.8;
  padding-bottom: 0.5rem;
  width: fit-content;
  margin-left: auto;
`;

const envelopImageStyles = css`
  position: absolute;
  bottom: 0.2rem;
  right: 0.2rem;
  opacity: 0.3;
  pointer-events: none;
`;

const inputStyle = css`
  font-family: inherit;
  font-size: inherit;
  resize: none;
  flex-grow: 1;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  padding: 1.5rem 0rem;
  background-color: ${bgColor};
  color: white;
  outline: none;
  border: none;
  &::placeholder {
    color: #65656b;
  }
`;
