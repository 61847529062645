import { css } from "@emotion/react";
import {
  ExtraFeaturesSection,
  HeroSection,
  HighlightsDemoSection,
  HighlightsSection,
  LandingPageFooter,
  LandingPageNavbar,
  StripSection,
} from "./Components";
import { mq, poppinsFontFamily } from "../../config/styles";
import { Helmet } from "react-helmet";
import {
  LANDING_PAGE_CONTENT,
  LANDING_PAGE_SECTION_IDS,
} from "../../constants/landing-page.constants";
import { AppLogoDivider } from "../../components";
import { Stack } from "@mui/material";
import { navbarHeight } from "../../config/theme";
import { useEffect } from "react";
import {
  refreshSessionSupabase,
  signInAnonymousUser,
} from "../auth-pages/utils/authHelpers";
import { ANALYTICS_TYPES } from "../../constants/global.constants";
import { postAnalytics } from "../../utils/customAnalytics";

/**
 * The LandingPage component is the main entry point for the FPL Champ app.
 * It displays the main sections of the app, including the hero section, highlights section, try highlights section, strip section, extra features section, and footer section.
 * It also sets up the user session by calling the refreshSessionSupabase and signInAnonymousUser functions.
 */
export const LandingPage = () => {
  useEffect(() => {
    (async () => {
      let { error } = await refreshSessionSupabase();
      if (error) await signInAnonymousUser();
    })();

    postAnalytics({ analyticsType: ANALYTICS_TYPES.LAND_ON_LANDING_PAGE });
  }, []);

  return (
    <Stack css={landingPageRootStyles}>
      <Helmet>
        <title>{LANDING_PAGE_CONTENT.META.TITLE}</title>
        <meta
          name="description"
          content={LANDING_PAGE_CONTENT.META.DESCRIPTION}
        />
      </Helmet>

      <div css={navBarContainerStyles}>
        <LandingPageNavbar />
      </div>

      <div css={heroSectionContainerStyles}>
        <HeroSection />
      </div>

      <div css={dividerContainerStyles}>
        <AppLogoDivider />
      </div>

      <div
        css={highlightsSectionContainerStyles}
        id={LANDING_PAGE_SECTION_IDS.HIGHLIGHTS_SECTION}
      >
        <HighlightsSection />
      </div>

      <div
        css={HighlightsDemoSectionStyles}
        id={LANDING_PAGE_SECTION_IDS.TRY_HIGHLIGHTS_SECTION}
      >
        <HighlightsDemoSection />
      </div>

      <div>
        <StripSection />
      </div>

      <div
        css={extraFeatureStyles}
        id={LANDING_PAGE_SECTION_IDS.WHY_FPLCHAMP_SECTION}
      >
        <ExtraFeaturesSection />
      </div>

      <div css={footerContainerStyles}>
        <LandingPageFooter />
      </div>
    </Stack>
  );
};

const navBarContainerStyles = css`
  position: sticky;
  top: 0;
  z-index: 100;
  height: fit-content;
  padding: 0;
`;

const landingPageRootStyles = css`
  ${poppinsFontFamily}
  scroll-behavior: smooth;

  & > * {
    padding-top: 3rem; // for anchor scroll padding sm
    scroll-padding-top: 5rem;
    font-family: inherit !important;

    ${mq["md"]} {
      padding-top: 5rem; // for anchor scroll padding lg
    }
  }
`;

const heroSectionContainerStyles = css`
  padding-top: 0 !important; // reset anchor scroll padding
  min-height: calc(100svh - ${navbarHeight});
  height: calc(100svh - ${navbarHeight});
`;

const dividerContainerStyles = css`
  width: 100%;
  padding-inline: 1rem;
  max-width: 500px;
  margin-inline: auto;
`;

const footerContainerStyles = css`
  margin: 1rem;
  margin-top: 2rem;

  ${mq["md"]} {
    margin-top: 4rem;
  }
`;

const extraFeatureStyles = css`
  margin-inline: 1rem;

  ${mq["md"]} {
    margin-top: 5rem;
  }

  ${mq["lg"]} {
    margin-inline: 5rem;
  }
`;

const highlightsSectionContainerStyles = css`
  /* background-color: red; */
  /* ${mq["md"]} {
    margin-top: 5rem;
    margin-inline: 7rem;
  } */
`;

const HighlightsDemoSectionStyles = css`
  margin-inline: 1rem;

  ${mq["md"]} {
    margin-inline: 5rem;
  }
`;
