import React from "react";

export const PrivacyIcon = ({
  fill = "#8E8E8E",
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7272 18.4837C18.2339 20.2014 16.2305 21.3968 14.0096 21.8953C11.7887 22.3938 9.46665 22.1692 7.38246 21.2543C5.29828 20.3393 3.56123 18.7819 2.42496 16.8096C1.2887 14.8373 0.812741 12.5534 1.06659 10.2913C1.32025 8.02913 2.29054 5.90728 3.83577 4.23569C5.38099 2.56409 7.42019 1.43033 9.65554 1M13.6983 1.00865C15.1994 1.29775 16.6209 1.90592 17.8666 2.79203C19.1123 3.67813 20.153 4.82148 20.9185 6.14471C21.6841 7.46796 22.1564 8.94022 22.3036 10.4619C22.4508 11.9835 22.2694 13.519 21.7716 14.9644"
        stroke={fill}
        strokeWidth="1.73077"
        strokeLinecap="round"
      />
      <path
        d="M9.37139 10.9109V8.84841C9.37139 7.59778 10.4045 6.58398 11.6791 6.58398C12.9537 6.58398 13.9868 7.59778 13.9868 8.84841V10.9109M8.79447 10.9109H14.5637C15.2029 10.9109 15.7175 11.4255 15.7175 12.0648V15.2378C15.7175 15.8771 15.2029 16.3917 14.5637 16.3917H8.79447C8.15524 16.3917 7.64062 15.8771 7.64062 15.2378V12.0648C7.64062 11.4255 8.15524 10.9109 8.79447 10.9109Z"
        stroke={fill}
        strokeWidth="1.73077"
      />
    </svg>
  );
};
