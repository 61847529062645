import { css } from "@emotion/react";
import { AppTabs } from "../../components";
import { Box, Stack } from "@mui/material";
import {
  MyTeamSummary,
  MiniLeagueSummary,
  HighlightsCarousel,
  FixturesSummary,
  TransfersWidget,
} from "./components";
import { mq } from "../../config/styles";
import {
  ICategory,
  useGetHighlightsTabs,
} from "../../utils/hooks/useGetHighlightsTabs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";
import { atom, getDefaultStore } from "jotai";
import { Helmet } from "react-helmet";
import { HOME_PAGE } from "../../constants/home-page.constants";
import {
  ANALYTICS_TYPES,
  APP_TOOLTIP_CONSTANTS,
} from "../../constants/global.constants";
import { postAnalytics } from "../../utils/customAnalytics";
import { useUserContext } from "../../context/UserContext";

const chosenCategoryAtom = atom("amusing");

/** Renders the home page UI */
export const HomePage = () => {
  const [selectedTab, setSelectedTab] = useState<ICategory>();
  const [tabsData] = useGetHighlightsTabs();
  const [searchParams, setSearchParams] = useSearchParams();
  const { liveGameWeek } = useGlobalInfoContext();
  const defaultStore = getDefaultStore();
  const { user } = useUserContext();

  useEffect(() => {
    if (user && !user.isAnonymous) {
      postAnalytics({
        analyticsType: ANALYTICS_TYPES.LAND_ON_HOME_PAGE,
        email: user.email,
      });
    } else if (user) {
      postAnalytics({
        analyticsType: ANALYTICS_TYPES.LAND_ON_HOME_PAGE,
      });
    }
  }, [user]);

  useEffect(() => {
    if (liveGameWeek) {
      searchParams.set(PATH_PARAMS.SELECTED_GAME_WEEK, String(liveGameWeek));
      setSearchParams(searchParams);
    }
  }, [liveGameWeek]);

  useEffect(() => {
    if (tabsData) {
      setSelectedTab(
        tabsData.find(
          (t) => t.category_id === defaultStore.get(chosenCategoryAtom)
        )
      );
    }
  }, [tabsData]);

  const handleTabsChange = (_: any, categoryId: string) => {
    if (!tabsData) return;
    const tab = tabsData.find((tab) => tab.category_id === categoryId)!;
    setSelectedTab(tab);
    defaultStore.set(chosenCategoryAtom, tab.category_id);
  };

  return (
    <Stack css={rootStyles}>
      <Helmet>
        <title>{HOME_PAGE.META.TITLE}</title>
      </Helmet>
      <AppTabs
        handleTabsChange={handleTabsChange}
        value={selectedTab?.category_id}
        tabs={
          tabsData &&
          tabsData.map((tab) => ({
            label: tab.category_display_name,
            value: tab.category_id,
            img: tab.category_icon,
            toolTipTitle:
              APP_TOOLTIP_CONSTANTS.MESSAGES.HIGHLIGHTS[tab.category_id].TITLE,
            toolTipSubTitle:
              APP_TOOLTIP_CONSTANTS.MESSAGES.HIGHLIGHTS[tab.category_id]
                .SUBTITLE,
          }))
        }
      />
      <Box css={gridContainerStyles}>
        <div>
          <HighlightsCarousel category={selectedTab} />
        </div>
        <div>
          <MyTeamSummary />
        </div>
        <div>
          <MiniLeagueSummary />
        </div>

        <div>
          <FixturesSummary />
        </div>
        <div
          css={css`
            display: none;
            ${mq["lg"]} {
              display: block;
            }
          `}
        />

        <div>
          <TransfersWidget />
        </div>
      </Box>
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
`;

const gridContainerStyles = css`
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;

  & > * {
    overflow: hidden;
    :first-of-type {
      width: 100%;
      overflow: auto;
    }
  }

  grid-template-rows: 1fr;

  ${mq["lg"]} {
    grid-template-columns: 1.8fr 1fr;
  }
`;
