import { createBrowserRouter, useParams } from "react-router-dom";
import { PATH_PARAMS, ROUTES } from "./routes";
import { AuthLayout, MainLayout, SelectLeagueLayout } from "../pages/layouts";
import {
  ForgotPasswordPage,
  HighlightsPage,
  PrivacyPage,
  RegisterPage,
  ResetPasswordPage,
  SignInPage,
  SingleHighlightsPage,
  TeamDetailsPage,
  TermsOfServicePage,
  ViewTeamPage,
  MiniLeagueLayout,
  MiniLeagueStandingPage,
  MiniLeagueCaptainsPage,
  MiniLeagueMostHurtfulPage,
  HomePage,
  FixturesPage,
  MiniLeagueNewEntriesPage,
  FixtureResultLayout,
  FixtureOwnershipPage,
  FixtureOverviewPage,
  ContactUsPages,
  TransfersPage,
  ManagerTransfersPage,
  LandingPage,
  NotFoundPage,
  CookiePolicyPage,
} from "../pages";
import { UserContextProvider } from "../context/UserContext";
import { AuthPageGuard, ProtectedRoute } from "../components";
import { DebugRouter } from "../components/DebugRouter";
import { GlobalInfoContextProvider } from "../context/GlobalInfoContext";
import { ErrorBoundary } from "../components";
import { GetStartedPage } from "../pages/auth-pages/GetStartedPage";
import { ElementType } from "react";
import { NavigateToHomePage } from "../utils/hooks/NavigateToHomePage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: ROUTES.CONTACT_US_PAGE,
    element: <ContactUsPages />,
  },
  {
    element: (
      <ErrorBoundary>
        <DebugRouter>
          <GlobalInfoContextProvider>
            <UserContextProvider />
          </GlobalInfoContextProvider>
        </DebugRouter>
      </ErrorBoundary>
    ),
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            path: "*",
            element: <NotFoundPage />,
          },
          {
            element: <SelectLeagueLayout />,
            children: [
              {
                path: ROUTES.BASE_HOME_PATH,
                element: <NavigateToHomePage />,
              },
              {
                path: ROUTES.HOME_PATH,
                element: (
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                ),
              },
              ...[ROUTES.HIGHLIGHTS_PATH, ROUTES.HIGHLIGHTS_CATEGORY_PATH].map(
                (route) => ({
                  path: route,
                  element: (
                    <ProtectedRoute>
                      <HighlightsPage />
                    </ProtectedRoute>
                  ),
                })
              ),
              {
                path: ROUTES.SINGLE_HIGHLIGHTS_PATH,
                element: (
                  <ProtectedRoute>
                    <SingleHighlightsPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: ROUTES.FIXTURES_PATH,
                element: (
                  <ProtectedRoute>
                    <FixturesPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: ROUTES.TRANSFERS_PATH,
                element: (
                  <ProtectedRoute>
                    <TransfersPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: ROUTES.MANAGER_TRANSFERS_PATH,
                element: (
                  <ProtectedRoute>
                    <ManagerTransfersPage />
                  </ProtectedRoute>
                ),
              },
              ...[ROUTES.MY_TEAM_PAGE, ROUTES.TEAM_PAGE].map((route) => ({
                path: route,
                element: (
                  <ProtectedRoute>
                    <RemountPage Page={ViewTeamPage} />
                  </ProtectedRoute>
                ),
              })),
              {
                path: ROUTES.COOKIES_POLICY_PAGE,
                element: <CookiePolicyPage />,
              },
              {
                path: ROUTES.PRIVACY_POLICY_PAGE,
                element: <PrivacyPage />,
              },
              {
                path: ROUTES.TERMS_OF_SERVES_PAGE,
                element: <TermsOfServicePage />,
              },
              {
                element: (
                  <ProtectedRoute>
                    <MiniLeagueLayout />
                  </ProtectedRoute>
                ),
                children: [
                  {
                    path: ROUTES.MINI_LEAGUE_STANDINGS_PAGE,
                    element: <MiniLeagueStandingPage />,
                  },
                  {
                    path: ROUTES.MINI_LEAGUE_CAPTAINS_PAGE,
                    element: <MiniLeagueCaptainsPage />,
                  },
                  {
                    path: ROUTES.MINI_LEAGUE_MOST_HURTFUL_PAGE,
                    element: <MiniLeagueMostHurtfulPage />,
                  },
                  {
                    path: ROUTES.MINI_LEAGUE_NEW_ENTRIES_PAGE,
                    element: <MiniLeagueNewEntriesPage />,
                  },
                ],
              },
              {
                element: (
                  <ProtectedRoute>
                    <FixtureResultLayout />
                  </ProtectedRoute>
                ),
                children: [
                  {
                    path: ROUTES.FIXTURE_RESULTS_OVERVIEW_PATH,
                    element: <FixtureOverviewPage />,
                  },
                  {
                    path: ROUTES.FIXTURE_RESULTS_OWNERSHIP_PATH,
                    element: <FixtureOwnershipPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: ROUTES.REGISTER_PAGE,
            element: (
              <AuthPageGuard>
                <RegisterPage />
              </AuthPageGuard>
            ),
          },
          {
            path: ROUTES.SIGN_IN_PAGE,
            element: (
              <AuthPageGuard>
                <SignInPage />
              </AuthPageGuard>
            ),
          },
          {
            path: ROUTES.TEAM_ID_PAGE,
            element: <TeamDetailsPage />,
          },
          {
            path: ROUTES.FORGOT_PASSWORD_PAGE,
            element: (
              <AuthPageGuard>
                <ForgotPasswordPage />
              </AuthPageGuard>
            ),
          },
          {
            path: ROUTES.GET_STARTED_PAGE,
            element: (
              <AuthPageGuard>
                <GetStartedPage />
              </AuthPageGuard>
            ),
          },
          {
            path: ROUTES.RESET_PASSWORD_PAGE,
            element: <ResetPasswordPage />,
          },
        ],
      },
    ],
  },
]);

/*
 * Remount the page component on changing a path param
 * Used for the ViewTeamPage
 * This is for the navigate team on the EplPLayerSummary Modal -> Ownership Table team cell click
 * The change in the URL doesn't remount the component so the Modal stays open
 */
function RemountPage({ Page }: { Page: ElementType }) {
  const urlParams = useParams();
  return <Page key={urlParams[PATH_PARAMS.TEAM_ID]} />;
}
