import { Box, Stack, Typography } from "@mui/material";
import { SerializedStyles, css } from "@emotion/react";
import { white } from "../../../config/theme";
import noise from "../../../assets/highlights/noise.png";
import noiseLg from "../../../assets/highlights/noise-lg.png";
import prohibited from "../../..//assets/highlights/prohibited.png";
import {
  mq,
  poppinsFontFamily,
  shadow,
  transition_all,
} from "../../../config/styles";
import { CHIPS } from "../../../constants/highlightsPage.constants";
import {
  IHighlightCard,
  IHighlightCardDetails,
  IImportantProperties,
  IMPORTANT_SUFFIX,
  TCategoryId,
} from "../../../utils/types/types";
import { Link } from "react-router-dom";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../../../utils/google-analytics";

interface IHighlightsCard {
  accentColor?: string;
  chipImageURL?: string;
  onChipClick?: (
    categoryId: TCategoryId,
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  cardStyles?: SerializedStyles;
  toHighlightURL?: string;
  importantProperties?: IImportantProperties[];
  highlightCardData: IHighlightCard;
  toTeamViewURL?: (teamId: string | number) => string;
}

/**
 * Represents a highlight card component displaying information
 * with an optional chip for categorization
 * and optional link to the single highlight page
 */

export const HighlightsCard = (props: IHighlightsCard) => {
  const navigate = useNavigateState();

  const {
    display_name,
    view_from,
    details,
    view_from_bottom_desc,
    view_from_top_desc,
    category_id,
    icon,
    view_from_bottom_invalid,
    view_from_top_invalid,
    invalid,
    is_not_available,
    not_available_title,
    not_available_desc,
  } = props.highlightCardData;

  const isLoading = !!(!details || !details.length);

  const mangerDetails: IHighlightCardDetails = !isLoading
    ? view_from === "top"
      ? details[0]
      : details.at(-1)!
    : {
        entry: 0,
        entry_name: "Loading...",
        id: 0,
        player_name: "",
        rank: 0,
      };

  let highlighComment = "";

  if (is_not_available) {
    highlighComment = not_available_desc!;
  } else {
    highlighComment =
      view_from === "top" ? view_from_top_desc : view_from_bottom_desc;
  }

  const isInvalid =
    view_from === "top" ? view_from_top_invalid : view_from_bottom_invalid;

  const handleOnClick = () => {
    if (props.toHighlightURL && details.length) {
      if (window.location.pathname.includes("home")) {
        TrackGoogleAnalyticsEvent(GA4_EVENTS.HOME_HIGHLIGHT_CARD_CLICKED);
      }

      navigate(props.toHighlightURL);
    }
  };

  const managerNameComponent =
    props.toTeamViewURL && !isLoading && !isInvalid && !is_not_available
      ? Link
      : "h2";

  const managerNameLink =
    props.toTeamViewURL && !isLoading
      ? props.toTeamViewURL(mangerDetails.entry)
      : undefined;

  return (
    <Stack
      onClick={handleOnClick}
      css={[
        highlightsCardStyle(isInvalid, props.accentColor, props.toHighlightURL),
        props.cardStyles,
      ]}
    >
      <picture css={noiseImageContainerStyle}>
        <source srcSet={noiseLg} media="(min-width: 500px)" />
        <img
          css={noiseImageStyle(props.accentColor!)}
          src={noise}
          alt="noise background"
        />
      </picture>
      <Stack alignItems={"start"} justifyContent={"center"} gap={3} mb={"1rem"}>
        {/* Category */}
        {props.chipImageURL && (
          <Stack
            component={"span"}
            onClick={(e) => props.onChipClick?.(category_id!, e)}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
            css={labelStyle(Boolean(props.onChipClick))}
          >
            <img src={props.chipImageURL} /> {CHIPS[category_id]}
          </Stack>
        )}

        {/* Highlight title  && Manger name && Description */}
        <Box css={titles}>
          <Typography variant="h1">{display_name}</Typography>

          {/* Manger Name if a manger meets the highlight */}
          <Typography
            component={managerNameComponent}
            to={managerNameLink}
            css={teamNameStyle(props.accentColor!)}
            variant="h2"
          >
            {is_not_available && !isInvalid && (
              <>
                <span>⌛</span>
                <span>{not_available_title}</span>
              </>
            )}

            {!is_not_available && isInvalid && (
              <>
                <span>
                  <img src={prohibited} alt="prohibited" />
                </span>
                <span>{invalid}</span>
              </>
            )}

            {!is_not_available && !isInvalid && mangerDetails.entry_name}
          </Typography>

          <Typography variant="h3">{highlighComment}</Typography>
        </Box>

        {/* Important props */}
        {props.importantProperties && (
          <Stack css={importantPropertiesContainer}>
            {props.importantProperties.map((imp) => (
              <Stack
                key={mangerDetails[imp.key]}
                css={importantPropertiesStyles(
                  imp.color === "primary" ? props.accentColor! : ""
                )}
              >
                <Typography variant="h1">
                  {imp.display_name} {IMPORTANT_SUFFIX[imp.type]}
                </Typography>
                <Typography>{mangerDetails[imp.key]}</Typography>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
      <img src={icon} css={imageContainer} />
    </Stack>
  );
};

const highlightsCardStyle = (
  isInValid: boolean | undefined,
  accentColor: string | undefined,
  toURL: string | undefined
) => css`
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  gap: 1rem;
  border-radius: 1rem;
  padding: 1rem 3rem 0 3rem;
  min-height: 12rem;
  position: relative;
  overflow: hidden;

  ${mq["sm"]} {
    height: 13rem;
    padding: 1rem 2.5rem 0 2.5rem;
  }

  ${mq["md"]} {
    height: 13rem;
    gap: 1.5rem;
    padding: 1.5rem 3.7rem 0 3.7rem;
  }

  ${isInValid && invalidStyles(accentColor)}
  ${toURL &&
  css`
    cursor: pointer;
  `}
`;

const invalidStyles = (accentColor: string | undefined) => css`
  *:not(div) {
    opacity: 0.6;
  }

  h2 {
    opacity: 1;
    * {
      opacity: 1;
    }

    img {
      width: clamp(1.2rem, 3vw, 2rem);
      aspect-ratio: 1/1;
      margin-right: 0.25rem;
      vertical-align: middle;
      margin-top: -0.3rem;
    }

    span:last-of-type {
      white-space: normal;
    }
  }

  * h3 {
    opacity: 1;
    color: ${accentColor} !important;
  }
`;

const noiseImageContainerStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const noiseImageStyle = (accentColor: string) => css`
  height: 100%;
  width: 100%;
  object-fit: cover;
  background: linear-gradient(to right top, #273441, #80cb4f);
  background: linear-gradient(to right top, #273441, ${accentColor});
`;

const labelStyle = (isClickable?: boolean) => css`
  ${transition_all}
  border-radius: 999999px;
  padding: 0.25rem 0.5rem 0.15rem;
  background-color: ${white};
  color: #181829;
  width: fit-content;
  font-weight: 600;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);

  ${isClickable &&
  css`
    :hover {
      cursor: pointer;
      box-shadow: ${shadow};
      background-color: #ededed;
    }
  `}

  img {
    width: 1rem;
  }
`;

const titles = css`
  & > * {
    margin-bottom: 0.5rem;
  }

  h1 {
    ${poppinsFontFamily}
    color: ${white};
    font-size: clamp(1rem, 2.7vw, 1.25rem);
    font-weight: 900;
  }

  h3 {
    color: ${white};
    font-size: 0.75rem;
    font-size: clamp(0.85rem, 1.5vw, 1rem);
    font-weight: 600;
    max-width: clamp(12rem, 60vw, 30rem);
  }
`;

const teamNameStyle = (accentColor: string) => css`
  ${poppinsFontFamily}
  color: #96edf4;
  color: ${accentColor};
  font-size: clamp(1.25rem, 3.3vw, 2rem);
  font-weight: 800;
  margin-bottom: 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 40vw;

  a {
    text-decoration: none;
    color: inherit;
  }

  ${mq["sm"]} {
    max-width: none;
  }

  ${mq["md"]} {
    max-width: 25vw;
  }

  ${mq["lg"]} {
    max-width: 35vw;
  }

  ${mq["xl"]} {
    max-width: none;
  }
`;

const imageContainer = css`
  align-self: center;
  max-height: 100%;
  max-width: 35%;
  object-fit: cover;
`;

const importantPropertiesContainer = css`
  width: 100%;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 1rem;
`;

const importantPropertiesStyles = (color: string) => css`
  color: white;
  color: ${color};
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  flex-grow: 1;
  flex-basis: 0;

  h1 {
    font-size: clamp(0.75rem, 1.4vw, 1rem);
    font-weight: bold;
    text-align: center;
  }

  h2 {
    font-size: clamp(1rem, 2vw, 1.5rem);
    font-weight: bold;
  }
`;
