import { useEffect, useRef, useState } from "react";
import { useGetFastHighlightsForLandingPage } from "../../../utils/hooks/useGetFastHighlightsForLandingPage";
import { ISingleHighlightCard } from "../../../utils/types/types";
import { Button, Carousel } from "../../../components";
import {
  HighlightsCard,
  HighlightsCardSkeleton,
} from "../../highlights-page/components";
import { css, SerializedStyles } from "@emotion/react";
import { bgColor, secondaryColor } from "../../../config/theme";
import { mq, primaryGradient, transition_all } from "../../../config/styles";
import { IconButton, Stack, Typography } from "@mui/material";
import crown from "../../../assets/landing-page/crown.svg";
import {
  GA4_EVENTS,
  TrackGoogleAnalyticsEvent,
} from "../../../utils/google-analytics";
import { SESSION_STORAGE } from "../../../config/app.config";
import Swiper from "swiper";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LANDING_PAGE_CONTENT } from "../../../constants/landing-page.constants";
import { CTAButtonWrapper } from "./CTAButton";

/**
 * HighlightsDemoSectionStep3
 *
 * This component renders a carousel with highlights for demo purpose. It renders
 * a random highlight from each category (stinking, amusing, basic). The component
 * also handles the logic for showing a "Explore Highlights" popup after user has
 * scrolled through the carousel.
 */
export const HighlightsDemoSectionStep3 = () => {
  const { highlightsWithCategory, liveGameWeek } =
    useGetFastHighlightsForLandingPage();

  const [showPopup, setShowPopup] = useState(false);
  const slideViewCount = useRef(0);

  const [randomHighLights, setRandomHighlights] = useState<
    ISingleHighlightCard[]
  >([]);

  useEffect(() => {
    if (highlightsWithCategory) {
      setRandomHighlights(() => []);
      const highLightsByCategory: { [key: string]: ISingleHighlightCard[] } = {
        stinking: [],
        amusing: [],
        basic: [],
      };

      for (const highlight of highlightsWithCategory) {
        highLightsByCategory[highlight.category_id].push(highlight);
      }

      Object.keys(highLightsByCategory).forEach((key) => {
        let randomIndex = 0;
        while (true) {
          randomIndex = Math.floor(
            Math.random() * highLightsByCategory[key].length
          );

          const isIncluded = randomHighLights.find(
            (item) =>
              item.highlight_id ===
              highLightsByCategory[key][randomIndex].highlight_id
          );

          if (!isIncluded) {
            break;
          }
        }

        setRandomHighlights((prev) => [
          ...prev,
          highLightsByCategory[key][randomIndex],
        ]);
      });
    }
  }, [highlightsWithCategory]);

  useEffect(() => {
    if (
      sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_LEAGUE_ID) &&
      sessionStorage.getItem(SESSION_STORAGE.LANDING_PAGE_LEAGUE_ID) &&
      !JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE.IS_DEMO_GA_EVENT_FIRED) ||
          "false"
      )
    ) {
      TrackGoogleAnalyticsEvent(GA4_EVENTS.USER_TRIED_DEMO);
      sessionStorage.setItem(
        SESSION_STORAGE.IS_DEMO_GA_EVENT_FIRED,
        JSON.stringify(true)
      );
    }
  }, []);

  const handleSlideChange = (swiper: Swiper) => {
    if (swiper.realIndex === 0 && slideViewCount.current > 0) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
    slideViewCount.current += 1;
  };

  return (
    <div css={carouselContainerStyles}>
      <Carousel
        onRealIndexChange={handleSlideChange}
        hideArrows={{ left: false, right: false }}
      >
        {randomHighLights.length !== 0
          ? randomHighLights
              .sort((a, b) => a.category_order - b.category_order)
              .map((highlight) => (
                <HighlightsCard
                  cardStyles={extraCardStyles}
                  key={`${highlight.category_id}${highlight.highlight_id}`}
                  accentColor={highlight.accent_color}
                  chipImageURL={highlight.category_icon}
                  highlightCardData={highlight}
                />
              ))
          : Array.from({ length: 3 }).map((_, index) => (
              <HighlightsCardSkeleton
                extraStyles={skeletonCardStyles}
                key={index}
              />
            ))}
      </Carousel>
      <span css={circlesStyles} />
      <Typography
        style={{ opacity: randomHighLights.length !== 0 ? 1 : 0 }}
        css={gameWeekStyles}
      >
        {`Showing highlights for GW ${liveGameWeek}`}
      </Typography>
      <ExploreHighlightsPopUp
        extraStyles={exploreHighlightsShowStyles(showPopup)}
        onCloseBtnClick={() => setShowPopup(false)}
      />
    </div>
  );
};

const exploreHighlightsShowStyles = (show: boolean) => css`
  ${transition_all}

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  ${show &&
  css`
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  `}
`;

const CTAButton = CTAButtonWrapper(Button);

const ExploreHighlightsPopUp = ({
  extraStyles,
  onCloseBtnClick,
}: {
  extraStyles: SerializedStyles;
  onCloseBtnClick: () => void;
}) => {
  return (
    <Stack css={[exploreHighlightsContainerStyles, extraStyles]}>
      <IconButton css={closeButtonStyles} onClick={onCloseBtnClick}>
        <CloseRoundedIcon />
      </IconButton>
      <Stack css={exploreHighlightsStyles}>
        <img src={crown} css={exploreHighlightsCrownImageStyles("top-left")} />
        <img
          src={crown}
          css={exploreHighlightsCrownImageStyles("bottom-left")}
        />
        <img
          src={crown}
          css={exploreHighlightsCrownImageStyles("bottom-right")}
        />
        <img src={crown} css={exploreHighlightsCrownImageStyles("top-right")} />
        <Stack>
          <Typography variant="h1">
            {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.DEMO.TITLE}
          </Typography>
          <Typography variant="h2">
            {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.DEMO.SUBTITLE}
          </Typography>
        </Stack>

        <CTAButton button="gradient">
          {LANDING_PAGE_CONTENT.HIGHLIGHTS_DEMO_SECTION_CONTENT.DEMO.BTN}
        </CTAButton>
      </Stack>
    </Stack>
  );
};

const carouselContainerStyles = css`
  width: 100%;
  position: relative;
`;

const skeletonCardStyles = css`
  background: ${bgColor};
`;

const extraCardStyles = css`
  ${mq["md"]} {
    height: 17rem;
  }
`;

const circlesStyles = css`
  --spread: clamp(1.3rem, 3.5vw, 10rem);

  border-radius: 9999px;
  display: block;
  width: 25rem;
  aspect-ratio: 1/1;
  position: absolute;

  /* bottom: -50%; */
  transform: translateY(-50%);
  left: -5%;
  width: 110%;
  z-index: -1;

  /* transition: -50% -50%; */
  pointer-events: none;

  background-color: rgba(124, 136, 155, 1);
  box-shadow: 0 0 0 var(--spread) rgba(155, 245, 251, 0.15),
    0 0 0 calc(2 * var(--spread)) rgba(255, 255, 255, 0.1);
  opacity: 0.5;

  ${mq["lg"]} {
    top: 50%;
    transform: translateY(-50%);
    right: -40%;
    left: auto;
    width: 25rem;
  }
`;

const gameWeekStyles = css`
  ${transition_all}
  margin-top: 0.5rem;
  color: ${secondaryColor};
  font-size: 0.875rem;
  font-weight: 600;
`;

const exploreHighlightsContainerStyles = css`
  overflow: hidden;
  width: 100%;
  height: 90%;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

const exploreHighlightsStyles = css`
  position: relative;
  isolation: isolate;
  overflow: hidden;
  background-color: ${bgColor}cc;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;

  h1 {
    ${primaryGradient}
    background-clip: text;
    color: transparent;
    font-weight: 700;
    font-size: clamp(1.2rem, 4vw, 1.5rem);
    text-align: center;
  }

  h2 {
    font-size: clamp(0.875rem, 2.5vw, 1.2rem);
    font-weight: 500;
    text-align: center;
  }
`;

const exploreHighlightsCrownImageStyles = (
  position: "top-left" | "top-right" | "bottom-left" | "bottom-right"
) => css`
  position: absolute;
  z-index: 1;
  opacity: 0.2;
  --left-right-position: -0.5rem;
  --x-position: 0;

  ${position === "top-left" &&
  css`
    top: 0;
    left: var(--left-right-position);
    translate: -20% 0;
    transform: rotateZ(45deg);
  `}

  ${position === "top-right" &&
  css`
    top: 0;
    right: var(--left-right-position);
    translate: 20% 0;
    transform: rotateZ(-35deg);
  `}

  ${position === "bottom-left" &&
  css`
    bottom: 0;
    left: var(--left-right-position);
    translate: -20% 0;
    transform: rotateZ(-35deg);
  `}

  ${position === "bottom-right" &&
  css`
    bottom: 0;
    right: var(--left-right-position);
    translate: 20% 0;
    transform: rotateZ(35deg);
  `}
`;

const closeButtonStyles = css`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 3;
  color: white;
  background-color: #2a292954;
`;
