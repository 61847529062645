import axios from "axios";
import { supabase } from "./supabase";
import { ENV_OPTIONS } from "./app.config";

let BASE_URL = "http://localhost:3000";
const ENV = import.meta.env.VITE_ENVIRONMENT;

if (ENV === ENV_OPTIONS.PREVIEW) {
  BASE_URL = "https://preview.api.fplchamp.com";
} else if (ENV === ENV_OPTIONS.DEV) {
  BASE_URL = "https://dev.api.fplchamp.com";
} else if (ENV === ENV_OPTIONS.PROD) {
  BASE_URL = "https://api.fplchamp.com";
}

// Global signal to cancel requests if game is being updated
export const controller = new AbortController();

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  signal: controller.signal, // add signal to base axios instance
});

// Add a request interceptor
axiosInstance.interceptors.request.use(async function (config) {
  // Do something before request is sent
  const {
    data: { session },
  } = await supabase.auth.getSession();

  config.headers["Authorization"] = session?.access_token;
  return config;
});
